import { BookOutlined, DesktopOutlined, HomeOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons';
import { BusinessOutlined, PersonOutline, ReportOutlined } from '@mui/icons-material';

const MenuItems = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Offline Access',
    path: '/dashboard/offline-access',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Course',
    path: '',
    icon: <HomeOutlined />,
    type: 'subMenu',
    children: [
      {
        title: 'Manage',
        path: '/dashboard/course',
        icon: <HomeOutlined />,
        type: 'simple',
      },
      {
        title: 'Categories',
        path: '/dashboard/categories',
        icon: <HomeOutlined />,
        type: 'simple',
      },
      {
        title: 'Subjects',
        path: '/dashboard/subjects',
        icon: <HomeOutlined />,
        type: 'simple',
      },
      {
        title: 'Chapters',
        path: '/dashboard/chapters',
        icon: <HomeOutlined />,
        type: 'simple',
      },
      {
        title: 'Topics',
        path: '/dashboard/topics',
        icon: <HomeOutlined />,
        type: 'simple',
      },
    ],
  },
  {
    title: 'Subscription',
    path: '',
    icon: <HomeOutlined />,
    type: 'subMenu',
    children: [
      { title: 'Plans', path: '/dashboard/subscription/subscription-plans', icon: <HomeOutlined />, type: 'simple' },
      {
        title: 'Offers',
        path: '/dashboard/subscription/subscription-offer',
        icon: <HomeOutlined />,
        type: 'simple',
      },
      {
        title: 'Subscribed Users',
        path: '/dashboard/subscription/subscribed-user',
        icon: <HomeOutlined />,
        type: 'simple',
      },
      {
        title: 'Free Trail Users',
        path: '/dashboard/subscription/trail-users',
        icon: <HomeOutlined />,
        type: 'simple',
      },
      {
        title: 'Free Trail Settings',
        path: '/dashboard/subscription/trail-settings',
        icon: <HomeOutlined />,
        type: 'simple',
      },
    ],
  },
  {
    title: 'University',
    path: '',
    icon: <HomeOutlined />,
    type: 'subMenu',
    children: [
      { title: 'Manage', path: '/dashboard/university/manage', icon: <HomeOutlined />, type: 'simple' },
      { title: 'Students', path: '/dashboard/university/students', icon: <HomeOutlined />, type: 'simple' },
      {
        title: 'Subscription Plans',
        path: '/dashboard/university/subscription-plans',
        icon: <HomeOutlined />,
        type: 'simple',
      },
    ],
  },
  {
    title: 'Simulation',
    path: '/dashboard/simulation',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Simulation Manage',
    path: '/dashboard/Simulation-Manage',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'MCQs',
    path: '/dashboard/mcq',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'MCQ Manage',
    path: '/dashboard/MCQ-Manage',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Videos',
    path: '/dashboard/videos',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Books',
    path: '/dashboard/books',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Glossary',
    path: '/dashboard/glossary',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: "Teacher's",
    path: '/dashboard/teacher',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: "Blog's",
    path: '/dashboard/blog',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Authoritative Literature',
    path: '/dashboard/authoritative-literature',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Mock Help',
    path: '/dashboard/mockhelp',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Placement Form',
    path: '/dashboard/placementform',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Student Detials',
    path: '/dashboard/studentdetails',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Subtitle Editor',
    path: '/dashboard/subtitle-editor',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'IRS Publications',
    path: '/dashboard/irs',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Blog Comment',
    path: '/dashboard/blog-comment',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'News',
    path: '/dashboard/view-news',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Course Comment',
    path: '/dashboard/course-comment',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Placement Jobs',
    path: '/dashboard/jobpages',
    icon: <HomeOutlined />,
    type: 'simple',
  },
  {
    title: 'Partner',
    path: '',
    icon: <BusinessOutlined />,
    type: 'subMenu',
    children: [{ title: 'Requests', path: '/dashboard/partner/requests', icon: <PersonOutline />, type: 'simple' }],
  },
  {
    title: 'Feedback & issues',
    path: '/dashboard/feedback-and-issues',
    icon: <MessageOutlined />,
    type: 'simple',
  },
  {
    title: 'Settings',
    path: '',
    icon: <SettingOutlined />,
    type: 'subMenu',
    children: [
      { title: 'Settings', path: '/dashboard/settings/device-login', icon: <DesktopOutlined />, type: 'simple' },
    ],
  },
  {
    title: 'Reports',
    path: '',
    icon: <BookOutlined />,
    type: 'subMenu',
    children: [{ title: 'all', path: '/dashboard/reports/all', icon: <BookOutlined />, type: 'simple' }],
  },
];

export default MenuItems;
