import { Card, InputNumber, Tag, Typography } from 'antd';
import '../styles/simTable.style.scss';
import { DownCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import * as math from 'mathjs';

export default function SimTable({
  tableRows,
  showModal,
  selectedOption,
  formulas,
  handleSetFormula,
  setOpen1,
  setSelected,
}) {
  useEffect(() => {
    if (formulas) {
      onValueChanged('');
    }
  });

  const onValueChanged = (e) => {
    let expression = '';
    let resultFieldId = '';
    console.log(formulas);
    for (let i = 0; i < formulas.length; i++) {
      // if (formulas[i].ids.includes(e)) {
      expression = formulas[i].formula;
      resultFieldId = formulas[i].resultField;
      const replacedExpression = expression.replace(/DIF\d+/g, (match) => {
        const fieldId = match;
        const realValue = document.getElementById(`dyninputfield_${fieldId}`).value.replaceAll(',', '') || 0; // Default to 0 if value not found
        return realValue;
      });
      console.log(replacedExpression);
      document.getElementById(resultFieldId).value = math.evaluate(replacedExpression);
      document.getElementById(resultFieldId).disabled = true;
      document.getElementById(`${resultFieldId}tag`).innerHTML = `${resultFieldId.replace(
        'dyninputfield_',
        ''
      )} = ${expression}`;
      console.log();
    }
  };

  return (
    <table className="simTableDynamic" border="1">
      <tbody>
        {tableRows.map((row, index) => {
          return (
            <tr
              onClick={(e) => {
                setOpen1 && setOpen1(true);
                console.log({ row });
                setSelected && setSelected(['table', row]);
              }}
            >
              {row.map((column, index2) =>
                column.type == 'text' ? (
                  <td
                    colSpan={column.colspan}
                    align="center"
                    style={{ textAlign: column.isHeader ? 'center' : 'left' }}
                  >
                    {column.isHeader ? (
                      <b>
                        <p>{column.value}</p>
                      </b>
                    ) : (
                      <p>{column.value}</p>
                    )}
                  </td>
                ) : column.type == 'input' ? (
                  <td colSpan={column.colspan} align="center">
                    <InputNumber
                      // disabled={column.disabled}
                      onChange={onValueChanged}
                      style={{
                        width: '10vw',
                        fontSize: '16px',
                        fontWeight: '500',
                      }}
                      id={column.id}
                      prefix={<>{column.prefix}</>}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                    <Tag
                      id={`${column.id}tag`}
                      onClick={() => handleSetFormula(column.id)}
                      color="green"
                      style={{ fontSize: '18px', fontWeight: '600', cursor: 'pointer' }}
                    >
                      {column.id.replace('dyninputfield_', '')}
                    </Tag>
                  </td>
                ) : column.type == 'select' ? (
                  <td colSpan={column.colspan} align="center">
                    <div
                      className="simSelectField"
                      onClick={() => showModal(column.id.split('dynselopt_')[1], column.options)}
                    >
                      <p id={column.id}>
                        {selectedOption[column.id.split('dynselopt_')[1]] === ''
                          ? ''
                          : selectedOption[column.id.split('dynselopt_')[1]]}
                      </p>
                      <DownCircleOutlined />
                    </div>
                  </td>
                ) : null
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
