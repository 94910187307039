import { Spin } from 'antd';
import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import MCQsearch from 'src/pages/MCQsearch';
import OfflineAccess from 'src/pages/offlineAccess';
import SimulationEdit from 'src/otherPages/SimulationEdit';
import SimulationManage from 'src/pages/SimulationManage';
import EditorComponent from 'src/pages/SubtitleEditing';

//pages

const LoginPage = lazy(() => import('../pages/LoginPage'));
const DashboardAppPage = lazy(() => import('../pages/DashboardAppPage'));
const DeviceLoginSettings = lazy(() => import('src/pages/settings/deviceLogin'));
const AllReports = lazy(() => import('src/pages/reports'));
const UniversitySubscriptionPlans = lazy(() => import('../pages/University/subscriptionPlans'));
const UniversityStudents = lazy(() => import('../pages/University/students'));
const TrailSettings = lazy(() => import('src/pages/subscription/trailSettings'));
const TrailUsers = lazy(() => import('src/pages/subscription/trailUsers'));
const Courses = lazy(() => import('../pages/Courses'));
const Categories = lazy(() => import('../pages/Categories'));
const SubCategory = lazy(() => import('../pages/subCategory'));
const Chapters = lazy(() => import('../pages/Chapters'));
const Subjects = lazy(() => import('../pages/Subjects'));
const Topics = lazy(() => import('../pages/Topics'));
const Blog = lazy(() => import('../pages/Blog'));
const Videos = lazy(() => import('../pages/Videos'));
const Books = lazy(() => import('../pages/Books'));
const Glossary = lazy(() => import('../pages/Glossary'));
const Teacher = lazy(() => import('../pages/Teacher'));
const MCQs = lazy(() => import('../pages/MCQs'));
const IRS = lazy(() => import('../pages/IRS'));
const Comment = lazy(() => import('../pages/comments'));
const CommentCourse = lazy(() => import('../pages/commentsCourse'));
const Catorize = lazy(() => import('../pages/catorize'));
const Commentstatus = lazy(() => import('../otherPages/commentstatus'));
const CommentstatusCourse = lazy(() => import('../otherPages/commentstatusCourse'));
const PlacementForm = lazy(() => import('../pages/PlacementForm'));
const StudentDetails = lazy(() => import('../pages/StudentDetails'));
const BlockStudents = lazy(() => import('../pages/SubtitleEditing'));
const CreateCoursePage = lazy(() => import('../otherPages/course/createCourse'));
const EditCoursePage = lazy(() => import('../otherPages/course/editCourse'));
const CreateCategoryPage = lazy(() => import('../otherPages/category/createCategory'));
const EditCategoryPage = lazy(() => import('../otherPages/category/editCategory'));
const CreateSubCategoryPage = lazy(() => import('../otherPages/category/createSubcategory'));
const EditSubCategoryPage = lazy(() => import('../otherPages/category/editSubcategory'));
const CreateSubjectPage = lazy(() => import('../otherPages/coursetypes/createcoursetypes'));
const EditSubjectPage = lazy(() => import('../otherPages/coursetypes/editcoursetypes'));
const CreateChapterPage = lazy(() => import('../otherPages/chapters/createchapters'));
const EditChapterPage = lazy(() => import('../otherPages/chapters/editchapters'));
const CreateGlossaryPage = lazy(() => import('../otherPages/glossary/createglossary'));
const ImportGlossaryPage = lazy(() => import('../otherPages/glossary/importglossary'));
const EditGlossaryPage = lazy(() => import('../otherPages/glossary/editglossary'));
const CreateTeacherPage = lazy(() => import('../otherPages/createJob'));
const EditTeacherPage = lazy(() => import('../otherPages/teacher/editTeacher'));
const CreateTopicsPage = lazy(() => import('../otherPages/topics/createtopics'));
const CreateSubTopicsPage = lazy(() => import('../otherPages/topics/createsubtopics'));
const EditTopicsPage = lazy(() => import('../otherPages/topics/edittopics'));
const CreateBlogPage = lazy(() => import('../otherPages/blog/createblog'));
const EditBlogPage = lazy(() => import('../otherPages/blog/editblog'));
const UplaodMCQ = lazy(() => import('../otherPages/mcq/uplaodMCQ'));
const UploadBook = lazy(() => import('../otherPages/books/uploadBook'));
const AddBook = lazy(() => import('../otherPages/books/addBook'));
const CreateVideoPage = lazy(() => import('../otherPages/video/createvideo'));
const Simulations = lazy(() => import('../pages/Simulations'));
const SimEditor = lazy(() => import('../otherPages/simEditor'));
const UploadSimEditor = lazy(() => import('../otherPages/simEditor/uploadsim'));
const PFormView = lazy(() => import('../otherPages/PFormView'));
const StudentForm = lazy(() => import('../otherPages/StudentForm'));
const SimulationView = lazy(() => import('../otherPages/SimulationView'));
const CreateIris = lazy(() => import('../otherPages/createirs'));
const Source = lazy(() => import('../otherPages/authorative/source'));
const Topic = lazy(() => import('../otherPages/topics/createtopics'));
const Upload = lazy(() => import('../otherPages/authorative/upload'));
const MockHelpCreate = lazy(() => import('../otherPages/mockhelp/createmockhelp'));
const MockHelpEdit = lazy(() => import('../otherPages/mockhelp/editmockhelp'));
const MockHelpView = lazy(() => import('../otherPages/mockhelp/viewmockhelp'));
const Authoritative = lazy(() => import('../pages/authorative'));
const MockHelp = lazy(() => import('../pages/MockHelp'));
const MCQManage = lazy(() => import('../pages/MCQManage'));
const ManageMCQ = lazy(() => import('../otherPages/ManageMCQ'));
// const SimulationManage = lazy(() => import('../pages/SimulationManage'));
const SubscriptionPage = lazy(() => import('../pages/Subscription'));
const SubscriptionOffer = lazy(() => import('../pages/SubscriptionOffer'));
const SubscribedUser = lazy(() => import('../pages/SubscribedUser'));
const ManageSimulation = lazy(() => import('../otherPages/ManageSimulation'));
const SubscriptionOfferCreate = lazy(() => import('../otherPages/subscriptions/subscriptionoffercreate'));
const SubscriptionCreate = lazy(() => import('../otherPages/subscriptions/subscriptionCreate'));
const SubscriptionEdit = lazy(() => import('../otherPages/subscriptions/subscriptionEdit'));
const JobPages = lazy(() => import('../pages/Jobs'));
const CreateJob = lazy(() => import('../otherPages/createJob'));
const News = lazy(() => import('../pages/News'));
const CreateNews = lazy(() => import('../otherPages/blog/createnews'));
const SimEditorV2 = lazy(() => import('../otherPages/simEditorV2'));
const Manageuniversity = lazy(() => import('../pages/University/manage'));
const FeedbackAndIssues = lazy(() => import('../pages/feedback/index'));
const PartnerRequests = lazy(() => import('src/pages/partners/requests'));

export default function AppRoutes() {
  return (
    <Suspense
      fallback={
        <Spin style={{ marginTop: '30%' }} size="large">
          <div className="content" />
        </Spin>
      }
    >
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard/app" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/mcqsearch" element={<MCQsearch />} />

        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="app" element={<DashboardAppPage />} />

          <Route path="offline-access" element={<OfflineAccess />} />

          <Route path="subscription">
            <Route path="subscription-plans" element={<SubscriptionPage />} />
            <Route path="subscribed-user" element={<SubscribedUser />} />
            <Route path="subscription-offer" element={<SubscriptionOffer />} />
            <Route path="trail-settings" element={<TrailSettings />} />
            <Route path="trail-users" element={<TrailUsers />} />
          </Route>

          <Route path="university">
            <Route path="manage" element={<Manageuniversity />} />
            <Route path="students" element={<UniversityStudents />} />
            <Route path="subscription-plans" element={<UniversitySubscriptionPlans />} />
          </Route>

          <Route path="sim-editor" element={<SimEditorV2 />} />

          <Route path="create-source" element={<Source />} />
          <Route path="create-Upload" element={<Upload />} />
          <Route path="create-topic" element={<Topic />} />
          <Route path="course" element={<Courses />} />
          <Route path="categories" element={<Categories />} />
          <Route path="subjects" element={<Subjects />} />
          <Route path="mockhelp" element={<MockHelp />} />
          <Route path="chapters" element={<Chapters />} />
          <Route path="subCategory" element={<SubCategory />} />
          <Route path="topics" element={<Topics />} />
          <Route path="videos" element={<Videos />} />
          <Route path="books" element={<Books />} />
          <Route path="glossary" element={<Glossary />} />
          <Route path="teacher" element={<Teacher />} />
          <Route path="simulation" element={<Simulations />} />
          <Route path="mcq" element={<MCQs />} />
          <Route path="catorize" element={<Catorize />} />
          <Route path="studentdetails" element={<StudentDetails />} />
          <Route path="subtitle-editor" element={<EditorComponent />} />
          <Route path="placementform" element={<PlacementForm />} />
          <Route path="simulationview" element={<SimulationView />} />
          <Route path="simulationedit" element={<SimulationEdit />} />
          <Route path="blog" element={<Blog />} />
          <Route path="irs" element={<IRS />} />
          <Route path="MCQ-Manage" element={<MCQManage />} />
          <Route path="MCQ-copy-move" element={<ManageMCQ />} />
          <Route path="Simulation-Manage" element={<SimulationManage />} />
          <Route path="Simulation-copy-move" element={<ManageSimulation />} />
          <Route path="blog-comment" element={<Comment />} />
          <Route path="course-comment" element={<CommentCourse />} />
          <Route path="status-comment" element={<Commentstatus />} />
          <Route path="status-comment-course" element={<CommentstatusCourse />} />
          <Route path="authoritative-literature" element={<Authoritative />} />
          <Route path="subscription-create" element={<SubscriptionCreate />} />
          <Route path="subscription-edit" element={<SubscriptionEdit />} />
          <Route path="subscription-offer-create" element={<SubscriptionOfferCreate />} />
          <Route path="create-course" element={<CreateCoursePage />} />
          <Route path="edit-course" element={<EditCoursePage />} />
          <Route path="view-news" element={<News />} />
          <Route path="create-news" element={<CreateNews />} />
          <Route path="create-blog" element={<CreateBlogPage />} />
          <Route path="edit-blog" element={<EditBlogPage />} />
          <Route path="create-irs" element={<CreateIris />} />
          <Route path="create-mockhelp'" element={<MockHelpCreate />} />
          <Route path="edit-mockhelp" element={<MockHelpEdit />} />
          <Route path="view-mockhelp" element={<MockHelpView />} />
          <Route path="create-category" element={<CreateCategoryPage />} />
          <Route path="edit-category" element={<EditCategoryPage />} />
          <Route path="upload-simulation" element={<UploadSimEditor />} />
          <Route path="create-subcategory" element={<CreateSubCategoryPage />} />
          <Route path="edit-subcategory" element={<EditSubCategoryPage />} />
          <Route path="create-subject" element={<CreateSubjectPage />} />
          <Route path="edit-subject" element={<EditSubjectPage />} />
          <Route path="create-chapter" element={<CreateChapterPage />} />
          <Route path="edit-chapter" element={<EditChapterPage />} />
          <Route path="create-glossary" element={<CreateGlossaryPage />} />
          <Route path="edit-glossary" element={<EditGlossaryPage />} />
          <Route path="create-teacher" element={<CreateTeacherPage />} />
          <Route path="edit-teacher" element={<EditTeacherPage />} />
          <Route path="create-topic" element={<CreateTopicsPage />} />
          <Route path="create-subtopic" element={<CreateSubTopicsPage />} />
          <Route path="edit-topic" element={<EditTopicsPage />} />
          <Route path="uplaod-mcqs" element={<UplaodMCQ />} />
          <Route path="uplaod-book" element={<UploadBook />} />
          <Route path="add-book" element={<AddBook />} />
          <Route path="create-video" element={<CreateVideoPage />} />
          <Route path="import-glossary" element={<ImportGlossaryPage />} />
          <Route path="placementformview" element={<PFormView />} />
          <Route path="studentform" element={<StudentForm />} />
          <Route path="jobpages" element={<JobPages />} />
          <Route path="create-job" element={<CreateJob />} />
          <Route path="feedback-and-issues" element={<FeedbackAndIssues />} />
          <Route path="mcq" element={<MCQs />} />
          <Route path="catorize" element={<Catorize />} />
          <Route path="partner">
            <Route path="requests" element={<PartnerRequests />} />
          </Route>
          <Route path="settings">
            <Route path="device-login" element={<DeviceLoginSettings />} />
          </Route>
          <Route path="reports">
            <Route path="all" element={<AllReports />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}
