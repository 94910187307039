const apiEndPoints = {
  course: {
    list: 'course/course-listing/',
    details: 'course/view-course-detail/',
    delete: 'course/delete-course/',
    createCourse: 'course/create-course/',
    editCourse: 'course/edit-course/',
  },
  categories: {
    list: 'course/course-category/',
    delete: 'course/delete-category/',
    subCategoryList: 'course/course-sub-category/',
    createCategory: 'course/create-course-category/',
    editCategory: 'course/edit-course-category/',
  },
  subject: {
    list: 'course/course-type-list/',
    list2: 'course/course-types/',
    delete: 'course/delete-course-type/',
    create: 'course/create-course-type/',
    edit: 'course/edit-course-type/',
  },
  chapter: {
    list: 'chapters/get-course-chapter-with-topics/',
    list2: 'chapters/list/',
    list3: 'course/course-subject-chapter-topics/',
    delete: 'chapters/delete-chapter/',
    create: 'chapters/create-chapter/',
    edit: 'chapters/edit-chapter/',
  },
  topic: {
    list: 'chapters/topic-list/',
    delete: 'chapters/delete-topic/',
    create: 'chapters/create-topic/',
    edit: 'chapters/edit-topic/',
  },
  simulation: {
    list: 'assessment/simulation-listing/',
    completeListData: 'course/course-subject-chapter-topics/',
    simListing: 'assessment/sim-listing/',
    delete: 'assessment/delete-question/',
    questionListing: 'assessment/question-listing/',
    mcqList: 'assessment/mcq-listing/',
    mcqDetail: 'assessment/question-detail/',
    mcqStatus: 'assessment/change-question-status/',
    simStatus: 'assessment/change-sim-status/',
    import: 'assessment/import/',
    create: 'assessment/create-simulation/',
    importSimulation: 'assessment/import-simulation/',
  },
  authoritative: {
    list: 'authoritative/get-authoritative-topic-list/',
    list2: 'authoritative/get-authoritative-source-list/',
    delete: 'authoritative/delete-authoritative-topic/',
    create: 'authoritative/create-authoritative-source/',
    create2: 'authoritative/create-authoritative-topics/',
    delete: 'authoritative/delete-authoritative-source/',
    questionList: 'assessment/question-listing/',
    importCSV: 'authoritative/import-csv/',
  },
  subscriptionUser: {
    list: 'notes/get-subscription-plan/',
    create: 'notes/create-subscription-plan/',
    update: 'notes/update-plan-feature/',
    changeStatus: 'notes/change-plan-status/',
    offerList: 'notes/get-offer-listing/',
    offerCreate: 'notes/create-offer/',
    changeOfferStatus: 'notes/change-offer-status/',
    subscriberList: 'course/get-subscribed-user/',
    studentList: 'user/student/listing/',
    coursesStudent: 'user/user-courses/',
    resetStudent: 'course/reset-information/',
  },
  homepages: {
    bloglist: 'notes/blog-list/',
    blogList2: 'notes/blog-category-list/',
    blogdelete: 'notes/delete-blog/',
    blogCreate: 'notes/create-blog/',
    blogEdit: 'notes/edit-blog/',
    noteCreate: 'notes/create-note/',
    createNews: 'notes/create-news/',
    editNews: 'notes/edit-news',
    commentlist: 'notes/blog-comment-list/',
    commentStatus: 'notes/change-comment-status/',
    courseCommentList: 'course/course-comment-list/',
    courseCommentStatus: 'course/change-comment-status/',
    joblist: 'course/get-job-listing/',
    departmentList: 'course/get-job-departments/',
    locationList: 'course/get-job-locations/',
    jobedit: 'course/edit-job/',
    jobcreate: 'course/create-job/',
    jobdelete: 'course/delete-job/',
    placementForm: 'course/placement-support-list/',
  },
  help: {
    list: 'chapters/help-list/',
    delete: 'chapters/delete-mock-help/',
    parentList: 'chapters/get-parent-help-list/',
    add: 'chapters/add-mock-help/',
    edit: 'chapters/edit-mock-help/',
  },
  books: {
    list: 'chapters/book-list/',
    delete: 'chapters/delete-chapter-book/',
    uploadBook: 'chapters/upload-book-id/',
    uploadChapterBook: 'chapters/upload-course-chapter-book/',
  },
  notes: {
    list: 'notes/news-list/',
    delete: 'notes/delete-news/',
  },
  glossary: {
    list: 'glossary/glossary-alphabet-list/',
    allList: 'glossary/list/#',
    filterlist: 'glossary/list/',
    delete: 'glossary/delete-glossary/',
    create: 'glossary/create-glossary/',
    edit: 'glossary/edit-glossary/',
    import: 'glossary/import-glossary/',
  },
  iris: {
    list: 'chapters/irs-file-list/',
    typelist: 'chapters/irs-type-list/',
    upload: 'chapters/upload-irs-pdf/',
  },
  teacher: {
    list: 'user/teacher/listing/',
    delete: 'user/teacher/delete/',
    create: 'user/teacher/create/',
    update: 'user/teacher/edit/',
  },
  video: {
    list: 'chapters/video-list/',
    delete: 'chapters/delete-chapter-video/',
    upload: 'chapters/upload-course-chapter-video/',
    caption: {
      get: 'chapters/view-video-caption-info',
      post: 'chapters/update-video-caption-info/',
    },
  },
  university: {
    students: {
      list: 'user/university-student-listing/',
    },
    manage: {
      create: 'notes/create-university/',
      list: 'notes/get-university-listing/',
      read: 'notes/get-university-detail/',
      update: 'notes/update-university/',
      delete: 'notes/delete-university/',
      subscribe: 'course/start-university-subscription/',
      shareInvoice: 'notes/share-invoice/',
      createOfflineSubscription: 'course/create-offline-university-subscription/',
      downloadOfflineInvoice: 'course/get-offline-invoice/',
      importStudents: 'notes/import-university-student/',
    },
    subscriptionPlans: {
      create: 'notes/create-university-subscription-plan/',
      update: '',
      list: 'notes/get-university-subscription-plan/',
    },
  },
  subscription: {
    trailSettings: {
      getDays: 'course/get-trail-days/',
      updateDays: 'course/change-trail-days/',
      getSubjects: 'course/get-trail-subjects/',
      updateSubjects: 'course/manage-trail-subjects/',
      getChapters: 'course/get-trail-chapters/',
      updateChapters: 'course/manage-trail-chapters/',
      trailUsers: 'notes/get-trail-user-listing/',
      exportTrailUsers: 'notes/export-trail-user-listing/',
    },
    trailUsers: {
      list: 'notes/get-trail-user-listing/',
      export: 'notes/export-trail-user-listing/',
    },
  },
  feedback: {
    list: 'notes/get-feedback-message/',
    reply: 'notes/send-reply-message/',
  },
  partners: {
    request: {
      list: 'notes/get-become-partner-request/',
      update: 'notes/change-partner-status/',
      delete: 'notes/delete-partner/',
    },
  },
  settings: {
    deviceLogin: {
      read: 'course/get-devices-restriction-setting/',
      update: 'course/update-devices-restriction-setting/',
      resetUserDevices: 'user/reset-devices/',
      singleUserDeviceLoginReportPDF: 'chapters/generate-devices-report/',
      singleUserDeviceLoginReportCSV: 'chapters/generate-devices-report-csv/',
      allUserDeviceLoginReport: 'chapters/generate-devices-report/',
    },
    websiteSettings: {
      updateReviewsCount: 'notes/update-reviews/',
      readReviewsCount: 'notes/get-reviews/',
    },
    user: {
      unblock: 'user/unblock-student-login',
    },
  },
  reports: {
    trailUsersCSV: 'notes/csv-export-trail-user-listing/',
    trailUsersPDF: 'notes/export-trail-user-listing/',
    subscriptionCSV: 'course/get-subscribed-user-csv/',
    subscriptionPDF: 'course/get-subscribed-user-pdf/',
    contactUsCSV: 'course/get-contact-us-csv-report/',
    contactUsPDF: 'course/get-contact-us-pdf-report/',
    becomeAPertnerCSV: 'notes/get-partner-us-csv-report/',
    becomeAPertnerPDF: 'notes/get-partner-us-pdf-report/',
    singleStudentDeviceReportCSV: 'chapters/generate-devices-report-csv/',
    multipleStudentDeviceReprotCSV: 'chapters/generate-devices-report-csv/',
    studentProfilePDF: 'user/student/listing-pdf-report/',
    studentProfileCSV: 'user/student/listing-csv-report/',
    invoiceCSV: 'notes/get-invoice-csv-report/',
    invoicePDF: 'notes/get-invoice-pdf-report/',
    initialSetupCSV: 'user/get-intial-setup-csv-report/',
    initialSetupPDF: 'user/get-intial-setup-pdf-report/',
    assessmentCSV: 'assessment/get-assessment-csv-report/',
    assessmentPDF: 'assessment/get-assessment-pdf-report/',
    readyScoreCSV: 'course/generate-ready-score-report-csv/',
    readyScorePDF: 'course/generate-ready-score-report-pdf/',
    performanceCSV: 'chapters/get-performance-csv-report/',
    performancePDF: 'chapters/get-performance-pdf-report/',
    attemptQuestionCSV: 'chapters/get-attempt-question-csv-report/',
    attemptQuestionPDF: 'chapters/get-attempt-question-pdf-report/',
    studyPlanCSV: 'user/get-study-plan-csv-report/',
    studyPlanPDF: 'user/get-study-plan-pdf-report/',
    practiceTestCSV: 'practice/get-practice-result-csv-report/',
    practiceTestPDF: 'practice/get-practice-result-pdf-report/',
    mockTestCSV: 'mock/get-mock-result-csv-report/',
    mockTestPDF: 'mock/get-mock-result-pdf-report/',
    userSettingsLogPDF: 'user/get-logs-pdf-report/',
    userSettingsLogCSV: 'user/get-logs-csv-report/',
    generalSettingCSV: 'user/get-setting-csv-report/',
    generalSettingPDF: 'user/get-setting-pdf-report/',
    sessionReportPDF: 'practice/download-session-report-admin/',
    sessionReportCSV: 'practice/download-session-report-admin-csv/',
    progressReportCSV: 'chapters/download-progress-report-csv-admin/',
    progressReportPDF: 'chapters/download-progress-report-admin/',
    videoReportCSV: 'chapters/download-video-report-admin-csv/',
    videoReportPDF: 'chapters/download-video-report-admin/',
    userNotesCSV: 'notes/download-user-notes-admin/',
    userNotesPDF: 'notes/download-user-notes-admin-pdf/',
  },
};

export default apiEndPoints;
