import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isLoggedIn } from 'src/services/localStorage.services';
import apiServices from 'src/services/apiServices';
import DashboardLayoutMain from 'src/layouts/layout';

const pathsWithoutHeader = ['/dashboard/sim-editor'];

function PrivateRoute({ element: Component, ...rest }) {
  let isLoggedIN = isLoggedIn();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {}, []);

  return isLoggedIN ? (
    <>
      {pathsWithoutHeader.includes(location.pathname) ? (
        <Outlet />
      ) : (
        <>
          <DashboardLayoutMain />
        </>
      )}
    </>
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;
