import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import apiServices from '../services/apiServices';

const OfflineAccess = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [courseIds, setCourseIds] = useState([]);
  const [planId, setPlanId] = useState('');
  const [numberOfDays, setNumberOfDays] = useState('');

  useEffect(() => {
    apiSer;
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      mobile,
      course_id: courseIds,
      plan_id: planId,
      number_of_days: numberOfDays,
    };

    try {
      const response = await fetch('{{url}}/api/course/offline-course-access/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Data posted successfully!');
      } else {
        console.error('Error posting data:', response.status);
      }
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="column" spacing={4}>
        <Stack direction="row" spacing={2}>
          <TextField
            label="First Name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            margin="normal"
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            margin="normal"
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField label="Email" value={email} onChange={(event) => setEmail(event.target.value)} margin="normal" />
          <TextField
            label="Mobile"
            value={mobile}
            onChange={(event) => setMobile(event.target.value)}
            margin="normal"
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={courseIds.includes('1')}
                onChange={(event) => {
                  if (event.target.checked) {
                    setCourseIds([...courseIds, '1']);
                  } else {
                    setCourseIds(courseIds.filter((id) => id !== '1'));
                  }
                }}
              />
            }
            label="US CPA"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={courseIds.includes('2')}
                onChange={(event) => {
                  if (event.target.checked) {
                    setCourseIds([...courseIds, '2']);
                  } else {
                    setCourseIds(courseIds.filter((id) => id !== '2'));
                  }
                }}
              />
            }
            label="US EA"
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Duration</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={planId}
              label="Age"
              onChange={(e) => setPlanId(e.target.value)}
            >
              <MenuItem value={10}>One Month</MenuItem>
              <MenuItem value={20}>Six Months</MenuItem>
              <MenuItem value={30}>One Year</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export default OfflineAccess;
