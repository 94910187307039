import React, { useEffect, useState } from 'react';
import { Select, Button, Menu, Dropdown, Flex, Input } from 'antd';
import apiServices from 'src/services/apiServices';
import apiEndPoints from 'src/services/apiEndPoints';

const getCues = (url) => {
  // we need a <video> element, but it can stay disconnected
  // it also doesn't need to point to an actual media
  console.log({ url });
  const vid = document.createElement('video');
  const track = document.createElement('track');
  track.default = true;
  vid.append(track);
  return new Promise((res, rej) => {
    track.onload = (evt) => res([...vid.textTracks[0].cues]);
    track.onerror = (evt, src, line, col, err) => rej({ evt, src, line, col, err }, 'invalid url');
    track.src = url;
  });
};

function getVTTURL(vttText) {
  console.log({ vttText });
  const vttBlob = new Blob([vttText], {
    type: 'text/plain',
  });
  return URL.createObjectURL(vttBlob);
}

const EditorComponent = () => {
  const [code, setCode] = useState();
  const [video, setVideo] = useState('');
  const [videoData, setVideoData] = useState([]);
  const [videoList, setVideoList] = useState([]);

  // function handleEditorChange(value, event) {
  //   console.log('here is the current model value:', value);
  // }

  const handleVideoChange = (e) => {
    setVideo(e);
  };

  const handleUpdate = () => {
    // Add your update logic here
    console.log('Update button clicked!');
  };

  useEffect(() => {
    apiServices.get(apiEndPoints.video.list).then(setVideoList);
  }, []);

  const getVideoData = () => {
    // Replace with your API call or data retrieval logic
    apiServices.get(`${apiEndPoints.video.caption.get}/${video}`).then((e) => {
      // const url = getVTTURL(e.data.slice(2));
      // getCues(url).then((c) => {
      //   console.log(c);
      //   setCode(c);
      // });
      setCode(`${e.data.slice(2)}`);
    });
  };

  // const options = {
  //   automaticLayout: true,
  //   endOfLinePreference: monaco.editor.EndOfLinePreference.CRLF,
  // };

  return (
    <div>
      <Flex vertical={false} gap={8}>
        <Select value={video} onChange={handleVideoChange} style={{ width: 360 }}>
          <Select.Option value="">Select Video</Select.Option>
          {videoList.map((e) => (
            <Select.Option value={e.id}>{e.name}</Select.Option>
          ))}
        </Select>
        <Button type="primary" onClick={getVideoData}>
          Get Video Data
        </Button>
      </Flex>
      <div style={{ padding: 0, marginTop: 20, marginBottom: 20 }}>
        <Input.TextArea
          value={`${code}`}
          onChange={(e) => setCode(e)}
          placeholder="Enter your text here..."
          style={{ width: '100%', height: 200, whiteSpace: 'pre-wrap' }}
        />
        {/* <Editor
          height="90vh"
          language="text"
          defaultValue={`${code}`}
          value={`${code}`}
          onChange={handleEditorChange}
          options={options}
        /> */}
        <Button type="primary" onClick={handleUpdate} style={{ marginTop: 20 }}>
          Update
        </Button>
        {videoData.length > 0 && (
          <ul>
            {videoData.map((video) => (
              <li key={video.id}>
                {video.title} - {video.url}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default EditorComponent;
