import { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from '@mui/material';
import apiServices from 'src/services/apiServices';
import apiEndPoints from 'src/services/apiEndPoints';

const MCQsearch = () => {
  const [courses, setCourses] = useState('');
  const [courseId, setCourseId] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState(null);
  const [subjectId, setSubjectId] = useState('');
  const [id,setId] = useState('');


  const handleCourseChange = (event) => {
    setCourses(event.target.value);
    if (event.target.value === 'cpa') {
      setSubjects([
        { id: 2, name: 'AUD' },
        { id: 10, name: 'BAR' },
        { id: 5, name: 'FAR' },
        { id: 9, name: 'ISC' },
        { id: 8, name: 'TCP' },
        { id: 1, name: 'REG' },
      ]);
      setCourseId(1);
      setSubjectId(null);
    } else if (event.target.value === 'ea') {
      setSubjects([
        { id: 6, name: 'Buisnesses' },
        { id: 3, name: 'Individuals' },
        { id: 7, name: 'Representatives practices and procedures' },
      ]);
      setCourseId(2);
      setSubjectId(null);
    }
    else if (event.target.value === 'cma'){
        setSubjects([])
        setCourseId(3);
        setSubjectId(null);
    }
  };

  const handleSubjectChange = (e) =>{
    const subjectIds = e.target.value;
    const selectedSubject = subjects.find((subject) => subject.id === parseInt(subjectIds, 10));
    setSubject(selectedSubject);
    setSubjectId(subjectIds);
  };
  const handleSearch = (e) =>{
    setId(e.target.value)
    apiServices
        .get(apiEndPoints.simulation.mcqList + `${courseId}/${subjectId}/${id}`)
        .then((data) =>{
            console.log(data)
        })
  }

  return (
    <div style={{ backgroundColor: "lightgrey", padding: "20px" ,height: '100vh' }}>
        <div style={{backgroundColor: 'white', padding: "20px" ,height: '100vh'}}>
            <div style={{display:'flex', flexWrap:'auto',padding: '20px'}}>
                <FormControl fullWidth>
                    <InputLabel id="course-select-label">Course</InputLabel>
                    <Select
                    labelId="course-select-label"
                    value={courses}
                    label="Course"
                    onChange={handleCourseChange}
                    >
                    <MenuItem value="cpa">CPA</MenuItem>
                    <MenuItem value="cma">CMA</MenuItem>
                    <MenuItem value="ea">EA</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="subject-select-label">Subjects</InputLabel>
                    <Select
                    labelId="subject-select-label"
                    value={subject?.id || ''}
                    label="Subjects"
                    onChange={handleSubjectChange}
                    >
                    {subjects.map((subject) => (
                        <MenuItem key={subject.id} value={subject.id}>
                        {subject.name}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </div>
        <div style={{display:'flex', flexWrap:'auto',padding: '20px'}}>    
            <TextField
                    className="textfield"
                    id="outlined-search"
                    label="Search"
                    onChange={handleSearch}
            />
            <Button
              className="buttondiv1"
              variant="contained"
              style={{ width: '150px',padding:'7px', margin:'8px',borderRadius:'10px',fontSize:'20px' }}
            >
                Edit
            </Button>
            <Button
              className="buttondiv1"
              variant="contained"
              style={{ width: '150px',padding:'7px', margin:'8px',borderRadius:'10px',fontSize:'20px' }}
            >
                Delete
            </Button>
        </div>
        <div>
            <p>
            Selected course: {courses}
            <br />
            Selected subject: {subjects[0]?.id || ''}
            <br/>
            subjectid : {subjectId}
            </p>
        </div>  
        </div>
    </div>
  );
};

export default MCQsearch;