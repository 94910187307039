import React from 'react';
import { Menu } from 'antd';
import { HomeOutlined, SettingOutlined, AppstoreOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import MenuItems from './config';

const { SubMenu } = Menu;

const SideNav = () => {
  const navigate = useNavigate();
  return (
    <Menu mode="inline">
      {MenuItems.map((item) => {
        if (item.type === 'simple') {
          return (
            <Menu.Item onClick={() => navigate(item.path)} key={item.title} icon={item.icon}>
              {item.title}
            </Menu.Item>
          );
        } else {
          return (
            <SubMenu key={item.title} icon={item.icon} title={item.title}>
              {item.children.map((subItem) => {
                if (subItem.type === 'simple') {
                  return (
                    <Menu.Item onClick={() => navigate(subItem.path)} key={subItem.title} icon={subItem.icon}>
                      {subItem.title}
                    </Menu.Item>
                  );
                }
              })}
            </SubMenu>
          );
        }
      })}
    </Menu>
  );
};

export default SideNav;
