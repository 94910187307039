import { UploadOutlined } from '@ant-design/icons';
import { ImageList, ImageListItem, Stack, TextField } from '@mui/material';
import { Button, Card, Divider, Upload } from 'antd';
import { useRef, useState } from 'react';

export default function ExhibitsTab({ exhibitFiles, setExhibitFiles, imageTitles, setImageTitles }) {
  let exhibitsTitle = useRef();

  const [tempExhibitFiles, setTempExhibitFiles] = useState([]);

  const fileList = [
    {
      uid: '-1',
      name: 'yyy.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
  ];

  const addExhibits = (title) => {
    setImageTitles([...imageTitles, title]);
    setExhibitFiles([...tempExhibitFiles]);

    console.log(title);
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setTempExhibitFiles([...tempExhibitFiles, e.target.files[0]]);
    }
    console.log(e.target.files);
  };

  return (
    <Card title="Upload Exhibits">
      <div
        className="mt-3"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: '1%',
        }}
      >
        <TextField
          sx={{
            paddingLeft: 3,
            paddingRight: 3,
          }}
          inputRef={exhibitsTitle}
        />
        <input type="file" id="myfile" name="myfile" onChange={handleFileChange} />
        <Button
          onClick={() => {
            addExhibits(exhibitsTitle.current.value);
          }}
          type="primary"
          size="large"
        >
          Add
        </Button>
      </div>
      <Divider />
      <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
        {exhibitFiles.map((item, index) => (
          <ImageListItem key={index}>
            <img
              src={(item && URL.createObjectURL(item)) || ''}
              // srcSet={item}
              // alt={item.title}
              loading="lazy"
            />
            <span>{imageTitles[index]}</span>
          </ImageListItem>
        ))}
      </ImageList>
    </Card>
  );
}
