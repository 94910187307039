import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { baseUrl } from 'src/utils/main';
import LinearProgress from '@mui/material/LinearProgress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import apiServices from 'src/services/apiServices';
import apiEndPoints from 'src/services/apiEndPoints';

// @mui
import {
  Card,
  Table,
  TextField,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { useNavigate } from 'react-router-dom';
let accessToken = window.localStorage.getItem('access');
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'ID of Simulation', alignRight: false },
  // { id: "Courses_Name", label: "Courses Name", alignRight: false },
  { id: 'Courses_ID', label: 'Chapter Name', alignRight: false },
  { id: 'Subject_ID', label: 'Topic Name', alignRight: false },
  // { id: 'Chapter_ID', label: 'Chapter', alignRight: false },
  // { id: 'Topic_ID', label: 'Parent Topic', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  // { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SimulationManage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [selectedName, setSelectedName] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(0);

  const token = window.localStorage.getItem('access');

  const [courseList, setCourseList] = useState([]);

  // const filteredCategories = [];
  const [filteredCategories, setfilteredCategories] = useState([]);

  const navigate = useNavigate();
  const [LinearState, setLinearState] = useState(true);
  const [downState, setdownState] = useState(true);
  // new code
  const [fetchcoursedetails, setFetchcoursedetails] = useState(false);

  const [listData, setListData] = useState([]);
  const [listCourse, setListCourse] = useState([]);
  const [listCourseSelected, setListCourseSelected] = useState([]);
  const [listCourseState, setListCourseState] = useState(false);
  const [listSubject, setListSubject] = useState([]);
  const [listSubjectSelected, setListSubjectSelected] = useState([]);
  const [listSubjectState, setListSubjectState] = useState(false);
  const loadSimQuestions = () => {
    setLinearState(true);
    setdownState(true);
    apiServices
      .get(apiEndPoints.simulation.simListing + `${listCourseSelected}/${listSubjectSelected}`)
      .then((data) => {
        console.log('Inner Data', data);

        let tempCourseList = [];
        data.map((row, index) => {
          tempCourseList.push({
            id: row.id,
            name: row.id,
            name2: row.idnumber,
            idNumber: row.idnumber,
            Chapter: row.chapter.name,
            Topic: row.chapter_topic?.name,
            visible: row.visible,
          });
        });
        setRowsPerPage(50);
        setfilteredCategories([...tempCourseList]);
        console.log('====================================');
        console.log('HERE');
        console.log('====================================');
        setLinearState(false);
        setdownState(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    console.log(listCourseState);
    // setLinearState(true);
    console.log('Loading List data', apiEndPoints.simulation.completeListData);
    // new code
    apiServices
      .get(apiEndPoints.simulation.completeListData)
      .then((data) => {
        setListData(data);
        // setLinearState(false);
        setListCourseState(true);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    console.log('change ->', listCourseState);
  }, [listCourseState]);

  // const [selectedCourseId, setSelectedCourseId] = useState('');
  useEffect(() => {
    console.log(listCourseState);
    if (listCourseState) {
      console.log(1);
      function FilterCourse() {
        let dataFetched = [];
        console.log({ listData });
        listData.map((rowdata, index) => {
          dataFetched.push({
            courseid: rowdata.id,
            coursename: rowdata.full_name,
          });
        });
        setListCourse(dataFetched);
        console.log('CourseList', dataFetched);
        setListCourseSelected(listData[1].id);
        setListCourseState(false);
        setListSubjectState(true);
      }
      FilterCourse();
    }
  }, [listCourseState]);

  useEffect(() => {
    if (listSubjectState) {
      console.log(2);
      function FilterSubject() {
        let dataFetched = [];
        console.log({ listCourseSelected, listData });
        listData.map((rowdata, index) => {
          if (rowdata.id === listCourseSelected) {
            console.log(rowdata);
            console.log('Subjectssss', rowdata.subjects);
            console.log('Subject', rowdata.subjects[0]?.id);
            rowdata.subjects != '' ? setListSubjectSelected(rowdata.subjects[0].id) : '';
            rowdata.subjects.map((rowdata1) => {
              dataFetched.push({
                subjectid: rowdata1.id,
                subjectname: rowdata1.name,
              });
            });
          }
        });

        setListSubjectSelected(listData[listCourseSelected].subjects[0].id);
        setListSubject(dataFetched);
        console.log('SubjectList', dataFetched);
        console.log('selected chapter', listSubjectSelected);
        setFetchcoursedetails(true);

        setListSubjectState(false);
      }
      FilterSubject();
    }
  }, [listSubjectState]);

  useEffect(() => {
    console.log({ fetchcoursedetails });
    if (fetchcoursedetails) {
      setLinearState(true);
      setdownState(true);
      setfilteredCategories([]);
      setSelected([]);
      (async () => {
        // new code
        apiServices
          .get(apiEndPoints.simulation.simListing + `${listCourseSelected}/${listSubjectSelected}`)
          .then((data) => {
            setRowsPerPage(50);
            setfilteredCategories([
              ...data.map((row, index) => ({
                id: row.id,
                name: row.id,
                name2: row.idnumber,
                idNumber: row.idnumber,
                Chapter: row.chapter.name,
                Topic: row.chapter_topic?.name,
                visible: row.visible,
              })),
            ]);
            console.log('====================================');
            console.log('HERE 262', [
              ...data.map((row, index) => ({
                id: row.id,
                name: row.id,
                name2: row.idnumber,
                idNumber: row.idnumber,
                Chapter: row.chapter.name,
                Topic: row.chapter_topic?.name,
                visible: row.visible,
              })),
            ]);
            console.log('====================================');
          })
          .catch(() => {});
      })();

      setFetchcoursedetails(false);
    }
  }, [fetchcoursedetails]);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredCategories.map((n) => n.name);
      setSelected(newSelecteds);
      console.log({ newSelecteds });
      // const newSelectedss = filteredCategories.map((n) => n.name2);
      // setSelectedName(newSelectedss);
      // console.log(newSelectedss);
      return;
    }
    setSelected([]);
    setSelectedName([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    console.log(newSelected.sort());
    setSelected(newSelected.sort());
  };

  const handleClickName = (event, name2) => {
    const selectedIndex = selected.indexOf(name2);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelectedName(newSelected);
    // console.log(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredCategories.length) : 0;

  const isNotFound = !filteredCategories.length && !!filterName;

  useEffect(() => {
    console.log({ filteredCategories });
  }, [filteredCategories]);

  return (
    <>
      {/* {LinearState ? (
        <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2} style={{ marginBottom: '2rem' }}>
          <LinearProgress color="secondary" />
        </Stack>
      ) : (
        ''
      )} */}

      <Helmet>
        <title> LMS | Categories </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Manage Simulation Questions
          </Typography>
          <div className="buttondiv">
            <Button
              className="buttondiv1"
              variant="contained"
              style={{ width: '150px' }}
              // disabled={downState}
              startIcon={<ContentCopyIcon />}
              onClick={() => {
                if (selected == '') {
                  alert("Please Select Simulation's");
                  return;
                }
                let text = `Do you want to Copy the ${selected.length} Simulation Selected.\nEither OK or Cancel.`;
                if (confirm(text) == true) {
                  navigate('/dashboard/Simulation-copy-move', { state: { Form: selected, ID: 'copy' } });
                }
              }}
            >
              Copy
            </Button>
            <Button
              variant="contained"
              // disabled={downState}
              style={{ width: '150px' }}
              startIcon={<OpenWithIcon />}
              onClick={() => {
                if (selected == '') {
                  alert("Please Select Simulation's");
                  return;
                }
                let text = `Do you want to Move the ${selected.length} Simulation Selected.\nEither OK or Cancel.`;
                if (confirm(text) == true) {
                  navigate('/dashboard/Simulation-copy-move', { state: { Form: selected, ID: 'move' } });
                }
              }}
            >
              Move
            </Button>
          </div>
        </Stack>
        <div className="field-div">
          <TextField
            className="textfield"
            id="outlined-select-currency"
            select
            style={{ width: '48%' }}
            value={listCourseSelected}
            label="Select"
            // disabled={downState}
            helperText="Select Course"
            onChange={(e) => {
              // setCourseselected(e.target.value);
              console.log(e.target.value);
              setListCourseSelected(e.target.value);
              setListSubjectState(true);
            }}
          >
            {listCourse.map((option) => (
              <MenuItem value={option.courseid}>{option.coursename}</MenuItem>
            ))}
          </TextField>
          <TextField
            id="outlined-select-currency"
            className="textfield"
            select
            style={{ width: '48%' }}
            value={listSubjectSelected}
            label="Select"
            // disabled={downState}
            helperText="Select Subject"
            onChange={(e) => {
              setListSubjectSelected(e.target.value);

              // setSubjectselected(e.target.value);
              console.log(e.target.value);
              setFetchcoursedetails(true);
            }}
          >
            {listSubject.map((option) => (
              <MenuItem value={option.subjectid}>{option.subjectname}</MenuItem>
            ))}
          </TextField>
        </div>
        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredCategories.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredCategories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    let SubjectIDID = row.id;
                    const { id, title, name, name2, idNumber, Chapter, Topic } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedUser}
                            onChange={(event) => {
                              handleClick(event, name);
                              // handleClickName(event, name2);
                            }}
                          />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={image} /> */}
                            <Typography variant="subtitle2">{idNumber}</Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{Chapter}</TableCell>

                        <TableCell align="left">{Topic}</TableCell>

                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Iconify
                              icon={'eva:trash-2-outline'}
                              onClick={(e) => {
                                let text = 'Do you want to Delete the Simulation Question .\nEither OK or Cancel.';

                                if (confirm(text) == true) {
                                  apiServices
                                    .delete(apiEndPoints.simulation.delete + id)
                                    .then((data) => {
                                      console.log(data);
                                      setFetchcoursedetails(true);
                                    })
                                    .catch(() => {});
                                }
                              }}
                            />
                            {row.visible === 1 ? (
                              <Iconify
                                icon={'mdi:eye'}
                                onClick={(e) => {
                                  let text = 'Do you want to make this Simulation inactive ?\n Either Ok or Cancel.';
                                  if (confirm(text) === true) {
                                    // console.log('MCQ Inactive', row);
                                    apiServices
                                      .post(apiEndPoints.simulation.simStatus + row.id, {
                                        status: 0,
                                      })
                                      .then((e) => loadSimQuestions())
                                      .catch((e) => console.log(e));
                                  }
                                }}
                              />
                            ) : (
                              <Iconify
                                icon={'mdi:eye-off'}
                                onClick={(e) => {
                                  let text = 'Do you want to make this Simulation active ?\n Either Ok or Cancel.';
                                  if (confirm(text) === true) {
                                    // console.log('MCQ Inactive', row);
                                    apiServices
                                      .post(apiEndPoints.simulation.simStatus + row.id, {
                                        status: 1,
                                      })
                                      .then((e) => loadSimQuestions())
                                      .catch((e) => console.log(e));
                                  }
                                }}
                              />
                            )}
                            {/* <Iconify
                              icon={'eva:edit-fill'}
                              onClick={(e) => {
                                let text = 'Do you want to Edit the Topic .\nEither OK or Cancel.';
                                if (confirm(text) == true) {
                                  navigate('/dashboard/edit-topic');
                                  localStorage.setItem('TopicID', SubjectIDID);
                                  localStorage.setItem('TopicTitle', name);
                                  localStorage.setItem('Topicdescription', description);
                                  localStorage.setItem('TopicChapterID', chapterid);
                                  localStorage.setItem('TopicCourseID', courseid);
                                  localStorage.setItem('TopicSubjectID', subjectid);
                                }
                              }}
                            /> */}
                          </Stack>
                        </TableCell>

                        {/* <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100, 250]}
            component="div"
            count={filteredCategories.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
