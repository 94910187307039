import { useEffect, useState } from 'react';
import SimTable from './DynamicTable/table';
import { Card, Col, Modal, Radio } from 'antd';
import DOMPurify from 'dompurify';

export default function SimBody({ simData, handleSetFormula, setOpen1, setSelected }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mcqOptions, setMcqOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(simData.totalSelectFields);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedOption(simData.totalSelectFields);
  }, [simData]);
  console.log(simData);

  const showModal = (index, options) => {
    setMcqOptions(options);
    setSelectedIndex(index);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRadioChange = (e) => {
    let list = selectedOption;
    list[selectedIndex] = e.target.value;
    setSelectedOption([...list]);
    // console.log(list);
  };

  const purifyHtml = (html) => {
    const clean = DOMPurify.sanitize(html);
    return clean;
  };

  return (
    <>
      {simData.sections.map((section, index) =>
        section[simData.allSectionNames[index]].type === 'paragraph' ? (
          <div
            dangerouslySetInnerHTML={{
              __html: purifyHtml(section[simData.allSectionNames[index]].value),
            }}
          ></div>
        ) : (
          <SimTable
            tableRows={section[simData.allSectionNames[index]].rows}
            selectedOption={selectedOption}
            showModal={showModal}
            formulas={simData.formulas}
            handleSetFormula={handleSetFormula}
            setOpen1={setOpen1}
            setSelected={setSelected}
          />
        )
      )}
      <Modal title="Select an option" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Col>
          {mcqOptions.map((option, index) => {
            return (
              <>
                <div className="simRadioFields">
                  <Radio
                    style={{ width: '100%' }}
                    key={index}
                    value={option}
                    onChange={(e) => handleRadioChange(e)}
                    checked={selectedOption[selectedIndex] === option}
                  >
                    {option}
                  </Radio>
                </div>
              </>
            );
          })}
        </Col>
      </Modal>
    </>
  );
}
