import React, { useState } from 'react';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Menu, Button, theme, Space, Popconfirm } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import SideNav from './sideNav';
import Logo from 'src/components/logo/Logo';
import { Logout } from 'src/services/localStorage.services';

const { Header, Sider, Content } = Layout;

const DashboardLayoutMain = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = () => {
    Logout();
    navigate('/login');
  };

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: colorBgContainer,
        }}
      >
        <div style={{ margin: '10px 20px' }}>
          <Logo />
        </div>

        <SideNav />
      </Sider>
      <Layout>
        <Header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: colorBgContainer,
            padding: 0,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <Space style={{ marginRight: '1%' }}>
            <Popconfirm
              onConfirm={handleLogout}
              title="Logout?"
              description="Are you sure to logout?"
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" icon={<LogoutOutlined />} danger>
                Logout
              </Button>
            </Popconfirm>
          </Space>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default DashboardLayoutMain;
