export const storeToken = (token) => {
  localStorage.setItem('access', token);
};
export const storeRefreshToken = (token) => {
  localStorage.setItem('refresh', token);
};

export const readToken = () => {
  return localStorage.getItem('access') || 'bearerToken';
};

export const readRefreshToken = () => {
  return localStorage.getItem('refresh') || 'bearerToken';
};

export const isLoggedIn = () => {
  return localStorage.getItem('access') !== null ? true : false;
};

export const Logout = () => {
  localStorage.clear();
};
